import React, { PureComponent } from 'react';
import KTCheckbox from 'components/common/KTCheckbox';
import './AdminFilter.scss';
import { isEmpty, isEqual } from 'utils/common';
import KtSearchSmall from '../KtSearchSmall';
import { onFiltersDropDownOpen, onFiltersInteractions } from 'config/analytics';
import { connect } from 'react-redux';
import CONFIG from 'config/configProps';
import SaveCollection from 'components/common/KTCollection/saveCollection';
import DisplayCollection from 'components/common/KTCollection/displayCollection';
import { isEmptyOrNull } from 'utils';
import { bindActionCreators } from 'redux';
import actions from 'redux/actions';

class KtDdFilters extends PureComponent {
  constructor(props) {
    super(props);
    const {
      selectedList,
      dataList,
      isAllSelected,
      singleSelect,
      filterName,
      selectedFiltersData,
    } = this.props;
    const isNameFilter = isEqual(
      filterName,
      CONFIG.PROTOTYPE_FILTERS.NAME.text
    );
    const isNameJobFilter =
      isNameFilter ||
      isEqual(filterName, CONFIG.PROTOTYPE_FILTERS.JOB_TITLE.text);
    let data = isNameJobFilter ? this.getDataList() : dataList;
    this.state = {
      selectedList: isAllSelected ? this.getAllIds() : selectedList,
      noData: false,
      isAllSelected: singleSelect ? false : isAllSelected,
      dataList: data,
      dataListToBeShown: data,
      selectedName: '',
      showSaveFilterPopup: false,
      isNameFilter,
      fullHeight: isEqual(filterName, CONFIG.PROTOTYPE_FILTERS.JOB_TITLE.text),
      selectedFiltersData,
    };
  }

  componentDidMount() {
    onFiltersDropDownOpen(`${this.props.filterName} Filter Opened`);
  }

  getDataList = () => {
    const { selectedFiltersData, dataList, stateField } = this.props;
    const selectedValues = selectedFiltersData?.[stateField] || [];
    const includesAll = (dataList, selectedValues) =>
      selectedValues.every(v => dataList.includes(v));
    let includes = includesAll(dataList, selectedValues);
    if (!includes) {
      let distinctVal = selectedValues.filter(val => !dataList.includes(val));
      let mergedArray = dataList.concat(distinctVal);
      return mergedArray;
    }
    return dataList;
  };

  getAllIds = () => {
    const length = this.props.dataList.length;
    if (length > 1)
      return [{ value: '-1' }, ...this.props.dataList].map(el => el.value);
    else if (length === 1) return [this.props.dataList[0].value];
  };

  handleRadioChange = selectedItem => {
    this.setState(
      { selectedList: [selectedItem.value], selectedName: selectedItem.label },
      () => {
        this.applyFilter();
      }
    );
  };

  toggleAllSelection = (isAllSelected, dataList) => {
    let allSelected = false;
    let selectedIds = {};
    let selectedValues = [];

    if (!isAllSelected) {
      dataList = dataList.filter(obj => {
        return obj.value !== '-1';
      });
      selectedIds = dataList.reduce(
        (acc, el) => ({ ...acc, [el.value]: true }),
        {}
      );
      selectedValues = dataList.reduce(
        (acc, el) => ({
          ...acc,
          value: el.value,
          label: el.label,
        }),
        {}
      );
      allSelected = true;
    }

    return [allSelected, selectedIds, selectedValues];
  };

  checkIdInAlllist = (id, allSelected, allOptions) => {
    return allSelected && allOptions?.find(el => id === el.paId?.toString());
  };

  checkAllSelected = (selectedIds, allSelected, allOptions) => {
    return !allSelected && allOptions?.every(el => selectedIds[el.paId]);
  };

  toggleSingleOptionSelection = (id, selectedIds, name, selectedValue) => {
    let isAllSelected;
    let selectedValues = !isEmptyOrNull(selectedValue) ? selectedValue : [];
    let index =
      selectedValues?.findIndex(element => element.value === id) || -1;
    if (selectedIds[id]) {
      if (selectedIds['-1']) {
        delete selectedIds['-1'];
      }

      isAllSelected = false;
      delete selectedIds[id];
      if (index !== -1) {
        selectedValues.splice(index, 1);
      }
    } else {
      selectedIds[id] = true;
      if (index === -1) {
        selectedValues.push({ value: id, label: name });
      }
    }

    return [isAllSelected, selectedIds, selectedValues];
  };

  handleCheckboxChange = (id, name) => {
    const { selectedList, dataList } = this.state;
    let selectedIds = selectedList.reduce(
      (acc, key) => ({ ...acc, [key]: true }),
      {}
    );
    let isAllSelected = this.state.isAllSelected || selectedIds['-1'];
    let selectedValues = this.state.selectedValues;

    if (isEqual(id, '-1')) {
      [isAllSelected, selectedIds, selectedValues] = this.toggleAllSelection(
        isAllSelected,
        dataList
      );
    } else {
      [isAllSelected, selectedIds, selectedValues] =
        this.toggleSingleOptionSelection(id, selectedIds, name, selectedValues);
    }
    this.setState({
      selectedList: Object.keys(selectedIds),
      isAllSelected,
      selectedValues,
    });
    this.props.hideCollection &&
      this.props.onCheckBoxChange(Object.keys(selectedIds), isAllSelected);
  };

  clearFilter = () => {
    this.setState({
      selectedList: [],
      isAllSelected: false,
    });
    this.props.clearFilter(this.props.stateField, [], 0);
  };

  applyFilter = selectedListByCollection => {
    const {
      dataList,
      stateField,
      onChange,
      filterName,
      selectedFiltersData,
      actions: { saveSelectedFiltersData },
    } = this.props;
    const { selectedList, selectedName, selectedValues } = this.state;
    let selectedListToPass = selectedListByCollection || selectedList;
    const selectedListLength = selectedListToPass.length;
    const dataListLength = dataList.length;
    const length =
      selectedListLength > dataListLength ? dataListLength : selectedListLength;
    saveSelectedFiltersData({
      ...selectedFiltersData,
      [stateField]: selectedValues,
    });
    onFiltersInteractions(filterName, `${filterName} Filter applied`);
    return onChange(stateField, selectedListToPass, length, selectedName);
  };

  showSearchResults = value => {
    if (value) {
      let data = this.state.dataList;
      let searchResultData = [];
      searchResultData = data.filter(el => {
        return el.label.toLowerCase().includes(value.toLowerCase());
      });

      this.setState({
        dataListToBeShown: searchResultData,
      });
    } else {
      this.setState({
        dataListToBeShown: this.state.dataList,
      });
    }
  };

  renderDropDown = (list, checked) => {
    const { singleSelect } = this.props;
    const { selectedList } = this.state;
    return list.map(el => {
      return (
        <div
          key={el.value}
          className={`filter-display ${
            singleSelect ? 'singleSelectFilter' : ''
          } ${checked ? 'highlighted' : ''}`}
        >
          {singleSelect ? null : (
            <KTCheckbox
              checked={checked || selectedList.includes(el.value)}
              checkboxColor="darkest-blue"
              handleChange={this.handleCheckboxChange}
              isReturn={true}
              value={el.value}
              name={el.label}
            />
          )}
          <div
            className={`filter-name ${singleSelect ? 'singleSelect' : ''}`}
            onClick={() =>
              singleSelect
                ? this.handleRadioChange(el)
                : this.handleCheckboxChange(el.value)
            }
          >
            {el.label}
          </div>
        </div>
      );
    });
  };

  renderAllOption = () => {
    const { isAllSelected } = this.state;
    return (
      <div className="filter-display">
        <img src="/img/checked_icon.png" />
        <div
          className="filter-name all"
          onClick={() => this.handleCheckboxChange('-1')}
        >
          {isAllSelected ? 'Deselect All' : 'Select All'}
        </div>
      </div>
    );
  };

  renderList = () => {
    const { selectedList, dataListToBeShown, fullHeight } = this.state;
    const {
      singleSelect,
      showSearch,
      placeHolder,
      showSelectedOnTop,
      hideAllOption,
    } = this.props;
    const selectedDropDownList = dataListToBeShown.filter(el =>
      selectedList.includes(el.value)
    );
    const uniqueItems = dataListToBeShown.filter(
      el => !selectedList.includes(el.value)
    );
    return (
      <>
        {showSearch && (
          <KtSearchSmall
            containerClassName="filter-list-search"
            onChangeHandler={this.showSearchResults}
            placeholder={placeHolder ? placeHolder : 'Search'}
          />
        )}
        <div
          className={`filter-list ${singleSelect ? 'showMaxHeight' : ''} ${
            fullHeight ? 'fullHeight' : ''
          }`}
        >
          {!singleSelect && dataListToBeShown.length > 1 && !hideAllOption
            ? this.renderAllOption()
            : null}
          {showSelectedOnTop ? (
            <>
              {this.renderDropDown(selectedDropDownList, true)}
              {this.renderDropDown(uniqueItems, false)}
            </>
          ) : (
            this.renderDropDown(dataListToBeShown)
          )}
        </div>
      </>
    );
  };

  render() {
    const {
      selectedList,
      noData,
      showSaveFilterPopup,
      dataList,
      isNameFilter,
    } = this.state;
    const { hideButtonFooter, className, saveFilter } = this.props;
    const disabled =
      noData ||
      isEmpty(selectedList) ||
      isEqual(this.props.selectedList, selectedList);

    const selectedListForSave = dataList.filter(el =>
      selectedList.includes(el.value)
    );
    return (
      <div className={`${className} admin-filter-container filter-container`}>
        {isNameFilter && !this.props.hideCollection && (
          <DisplayCollection
            memberList={dataList}
            closeModal={() => this.setState({ showSaveFilterPopup: false })}
            applyFilter={this.applyFilter}
            clearFilter={this.clearFilter}
          />
        )}
        {showSaveFilterPopup && (
          <SaveCollection
            selectedDropDownList={selectedListForSave}
            selectedList={selectedList}
            memberList={dataList}
            applyFilter={this.applyFilter}
            closeModal={() => this.setState({ showSaveFilterPopup: false })}
          />
        )}
        {isEmptyOrNull(dataList) || dataList[0]?.value === '00000'
          ? null
          : this.renderList()}
        {hideButtonFooter ? null : (
          <div className="button-footer">
            <div className="clear" onClick={this.clearFilter.bind(this)}>
              Clear
            </div>
            {saveFilter && (
              <div
                className={`apply save ${disabled ? 'disabled' : ''}`}
                onClick={e => {
                  e.nativeEvent.stopImmediatePropagation();
                  this.setState({ showSaveFilterPopup: true });
                }}
              >
                Save
              </div>
            )}
            <div
              className={`apply ${disabled ? 'disabled' : ''}`}
              onClick={e => {
                e.nativeEvent.stopImmediatePropagation();
                this.applyFilter();
              }}
            >
              Apply
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    memberList: state.filterDataPt.fetchTeamMemberList?.results || {},
    selectedFiltersData: state.filterDataPt?.selectedFiltersData || [],
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    saveSelectedFiltersData: bindActionCreators(
      actions.protoTypeActions.saveSelectedFiltersData,
      dispatch
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(KtDdFilters);
