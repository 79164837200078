/**
 * constant denoting Begin an API Call.
 */
export const BEGIN_API_CALL = 'BEGIN_API_CALL';
/**
 * constant denoting End an API Call.
 */
export const END_API_CALL = 'END_API_CALL';
export const UPDATE_USER_HRID = 'UPDATE_USER_HRID';
export const SET_HEADER3_DATE = 'SET_HEADER3_DATE';
export const SET_CALENDER_DROPDOWN_VALUE = 'SET_CALENDER_DROPDOWN_VALUE';
export const INITIAL_DATE = 'INITIAL_DATE';
export const UPDATE_TOTAL_RECORDS = 'UPDATE_TOTAL_RECORDS';
export const UPDATE_EMPlOYEE_INITIAL_DATA = 'UPDATE_EMPlOYEE_INITIAL_DATA';
export const SET_DROPDOWN_GLOBAL_HEADER_VALUE =
  'SET_DROPDOWN_GLOBAL_HEADER_VALUE';
export const UPDATE_HIGHEST_ROLE_OF_LOGGED_IN_USER =
  'UPDATE_HIGHEST_ROLE_OF_LOGGED_IN_USER';
export const UPDATE_SECTOR_DETAILS = 'UPDATE_SECTOR_DETAILS';
export const UPDATE_SUB_SECTOR_DETAILS = 'UPDATE_SUB_SECTOR_DETAILS';
export const UPDATE_SELECTED_SECTION_DETAILS =
  'UPDATE_SELECTED_SECTION_DETAILS';
export const UPDATE_SELECTED_SECTION_DETAIL_VALUE =
  'UPDATE_SELECTED_SECTION_DETAIL_VALUE';
export const BACK_TO_LIST_VIEW_PAGE = 'BACK_TO_LIST_VIEW_PAGE';
export const UPDATE_PA_ADMIN_VIEW_DETAILS = 'UPDATE_PA_ADMIN_VIEW_DETAILS';
export const UPDATE_TABLEAU_FILTER_PARAMS = 'UPDATE_TABLEAU_FILTER_PARAMS';
export const SET_DROPDOWN_MEMBER_GLOBAL_HEADER_VALUE =
  'SET_DROPDOWN_MEMBER_GLOBAL_HEADER_VALUE';
export const UPDATE_TOAST_NOTIFICATION_DETAILS =
  'UPDATE_TOAST_NOTIFICATION_DETAILS';
export const SET_MANAGER_VALUE = 'SET_MANAGER_VALUE';
export const CLOSE_GRANT_MODAL = 'CLOSE_GRANT_MODAL';
export const SET_ADMIN_CLICK = 'SET_ADMIN_CLICK';
export const SHOW_PAGE_CONTENT = 'SHOW_PAGE_CONTENT';
export const SET_KT_SCORECARD_REPORT_DETAILS =
  'SET_KT_SCORECARD_REPORT_DETAILS';
export const CHECK_IE_BROWSER = 'CHECK_IE_BROWSER';
export const UPDATE_SA_PA_LIST = 'UPDATE_SA_PA_LIST';
export const SET_ALL_PA_LIST = 'SET_ALL_PA_LIST';
export const UPDATE_PA_PA_LIST = 'UPDATE_PA_PA_LIST';
export const UPDATE_TABLEAU_STRING = 'UPDATE_TABLEAU_STRING';
export const LOAD_HOME_EDIT_PAGE = 'LOAD_HOME_EDIT_PAGE';
export const UPDATE_MANAGED_BY = 'UPDATE_MANAGED_BY';
export const UPDATE_SELECTED_TEAM_MEMBERS = 'UPDATE_SELECTED_TEAM_MEMBERS';
export const UPDATE_TEAM_MEMBERS_LIST = 'UPDATE_TEAM_MEMBERS_LIST';
export const ALL_TEAM_MEMBER_SELECTED = 'ALL_TEAM_MEMBER_SELECTED';
export const UPDATE_API_REQUEST_DATA = 'UPDATE_API_REQUEST_DATA';
export const UPDATE_COLLECTION_DATA = 'UPDATE_COLLECTION_DATA';
export const UPDATE_DISPLAY_NAME = 'UPDATE_DISPLAY_NAME';
export const UPDATE_USER_TYPES = 'UPDATE_USER_TYPES';
export const UPDATE_USER_TYPE_DATA = 'UPDATE_USER_TYPE_DATA';
export const UPDATE_PATH = 'UPDATE_PATH';
export const UPDATE_ICON_INFORMATION = 'UPDATE_ICON_INFORMATION';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const FETCH_PEOPLE_IMAGES_SUCCESS = 'FETCH_PEOPLE_IMAGES_SUCCESS';
export const FETCH_PEOPLE_IMAGES_IN_PROGRESS =
  'FETCH_PEOPLE_IMAGES_IN_PROGRESS';
export const SAVE_PEOPLE_DETAILS = 'SAVE_PEOPLE_DETAILS';
export const PASS_GEO_DETAILS = 'PASS_GEO_DETAILS';
export const IS_COLLECTION_APPLIED = 'IS_COLLECTION_APPLIED';
export const IS_GEO_MANAGED = 'IS_GEO_MANAGED';
export const IS_GEO_MANAGER = 'IS_GEO_MANAGER';
export const IS_GEO_LEAD = 'IS_GEO_LEAD';
export const UPDATE_IMPERSONATOR_LIST = 'UPDATE_IMPERSONATOR_LIST';
export const UPDATE_SELECTED_MEMBER_BY_IMPERSONATOR =
  'UPDATE_SELECTED_MEMBER_BY_IMPERSONATOR';
export const UPDATE_GEO_REGION_LIST = 'UPDATE_GEO_REGION_LIST';
export const FETCH_IMPERSONATOR_LIST = 'FETCH_IMPERSONATOR_LIST';
export const UPDATE_GEO_MARKET_LIST = 'UPDATE_GEO_MARKET_LIST';
export const SHOW_TOOLTIP_POPUP = 'SHOW_TOOLTIP_POPUP';
export const GET_KT_USER_DETAILS = 'GET_KT_USER_DETAILS';
export const SET_ACCESS_FLAG_FOR_KT_FROM_OKTA =
  'SET_ACCESS_FLAG_FOR_KT_FROM_OKTA';
export const UPDATE_DEFAULT_MEMBER_LIST = 'UPDATE_DEFAULT_MEMBER_LIST';
export const FILTER_DATA_FETCH_SUCCESS = 'FILTER_DATA_FETCH_SUCCESS';
export const FILTER_DATA_FETCH_FAILURE = 'FILTER_DATA_FETCH_FAILURE';
export const UPDATE_LOGGED_IN_EMPLOYEE_DATA = 'UPDATE_LOGGED_IN_EMPLOYEE_DATA';
export const UPDATE_LOGGED_IN_KT_USER_DATA = 'UPDATE_LOGGED_IN_KT_USER_DATA';
export const UPDATE_USER_HIGHEST_ROLE = 'UPDATE_USER_HIGHEST_ROLE';
export const SET_USER_LIST = 'SET_USER_LIST';
export const UPDATE_IS_MENU_OPEN = 'UPDATE_IS_MENU_OPEN';
export const UPDATE_BC_TABLEAU_PARAMS = 'UPDATE_BC_TABLEAU_PARAMS';
export const SET_PA_LIST = 'SET_PA_LIST';
export const SET_SECTOR_LIST = 'SET_SECTOR_LIST';
export const SET_SUB_SECTOR_LIST = 'SET_SUB_SECTOR_LIST';
export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT';
export const SET_SIDEBAR_PATHNAME = 'SET_SIDEBAR_PATHNAME';
export const SAVE_APPLIED_FILTERS = 'SAVE_APPLIED_FILTERS';
export const UPDATE_IS_FILTER_OPEN = 'UPDATE_IS_FILTER_OPEN';
export const UPDATE_LAST_UPDATED_DATE = 'UPDATE_LAST_UPDATED_DATE';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const UPDATE_SELECTED_PULSE_QUESTION = 'UPDATE_SELECTED_PULSE_QUESTION';
export const SAVE_SELECTED_USER_ID_MEMBER = 'SAVE_SELECTED_USER_ID_MEMBER';
export const SAVE_SELECTED_USER_ID_BUSINESS = 'SAVE_SELECTED_USER_ID_BUSINESS';
export const IS_MEMBER_FILTER_SELECTED = 'IS_MEMBER_FILTER_SELECTED';
export const SAVE_SELECTED_VIEW = 'SAVE_SELECTED_VIEW';
export const UPDATE_SELECTED_BILLABILITY_QUESTION =
  'UPDATE_SELECTED_BILLABILITY_QUESTION';
export const UPDATE_SELECTED_TOP_PROJECTS_QUESTION =
  'UPDATE_SELECTED_TOP_PROJECTS_QUESTION';
export const UPDATE_SELECTED_USER_BY_IMPERSONATOR =
  'UPDATE_SELECTED_USER_BY_IMPERSONATOR';
export const UPDATE_IMPERSONATED_USER_DETAILS =
  'UPDATE_IMPERSONATED_USER_DETAILS';
export const RESET_DATA = 'RESET_DATA';
export const UPDATE_ABODE_ANALYTICS_DETAILS = 'UPDATE_ABODE_ANALYTICS_DETAILS';
export const SAVE_PA_TOPICS_SUBTOPICS = 'SAVE_PA_TOPICS_SUBTOPICS';
export const UPDATE_LOGGED_IN_USER_ROLE = 'UPDATE_LOGGED_IN_USER_ROLE';
export const UPDATE_LOGGED_IN_USER_ID = 'UPDATE_LOGGED_IN_USER_ID';
export const UPDATE_FILTER_STATE = 'UPDATE_FILTER_STATE';
export const UPDATE_FILTER_STATE_BC = 'UPDATE_FILTER_STATE_BC';
export const SAVE_APPLIED_FILTERS_BC = 'SAVE_APPLIED_FILTERS_BC';
export const UPDATE_SELECTED_DATE = 'UPDATE_SELECTED_DATE';
export const SAVE_APPLIED_COLLECTION = 'SAVE_APPLIED_COLLECTION';
export const SAVE_SELECTED_DATA_FOR_FILTERS = 'SAVE_SELECTED_DATA_FOR_FILTERS';
export const SAVE_APPLIED_CHARGES_HOURS_FILTERS =
  'SAVE_APPLIED_CHARGES_HOURS_FILTERS';
