import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CONFIG from 'config/configProps';
import { Grid } from 'semantic-ui-react';
import KTBillability from './KTBillability';
import { isEqual } from 'utils/common';
import { globalAdobeAnalytics } from 'config/analytics';
import { bindActionCreators } from 'redux';
import actions from 'redux/actions';

const getHeading = activeIndex => {
  let heading = '',
    keyName = '';
  switch (activeIndex) {
    case 0:
      heading = 'Billability by Business';
      keyName = 'billabilityByBusiness';
      break;
    case 1:
      heading = 'Billability by Team Member';
      keyName = 'billabilityByTeamMember';
      break;
    default:
      heading = '';
      keyName = '';
  }
  return { heading, keyName };
};

const KTBilling = props => {
  const { adobeData } = props;
  const history = useHistory();
  const location = useLocation();
  let path = location.pathname;
  const activeIndex = isEqual(path, CONFIG.PROTOTYPE_URLS.BUSINESS_BILLING)
    ? 0
    : 1;
  const currentPage = getHeading(activeIndex);
  useEffect(() => {
    if (!adobeData?.[currentPage.keyName]) {
      const isScriptLoaded = globalAdobeAnalytics(
        `KTDashboard-${currentPage.heading}`
      );
      isScriptLoaded && updateAdobeData();
    }
  }, [adobeData, activeIndex]);

  const updateAdobeData = () => {
    if (!adobeData?.[currentPage.keyName])
      props.updateAdobeDetails({ [currentPage.keyName]: true });
  };

  return (
    <Grid className="Kt_main_body">
      <Grid.Column className="Kt_main_body_tabs" width={16}>
        <KTBillability
          selectedIndex={activeIndex}
          history={history}
          pageUrl={`KTDashboard-${currentPage.heading}`}
        />
      </Grid.Column>
    </Grid>
  );
};

const mapStateToProps = store => {
  return {
    adobeData: store.adobeData.adobeAnalyticsData,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    updateAdobeDetails: bindActionCreators(
      actions.adobeAnalyticsActions.updateAdobeDetails,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KTBilling);
