import { connect } from 'react-redux';
import KtImpersonator from './KtImpersonator';
import { bindActionCreators } from 'redux';
import { selectors } from 'redux/reducers';
import actions from 'redux/actions';
import { API_CALL_IDENTIFIERS } from 'redux/constants';
/**
 * method to create PROPS for seed Data  by fetching them from Store, using selectors
 * @param {*} state Application State
 * @param {*} apiIdentifier API Identifier (State Identifier)
 */
function getPropsForSeedData(state, apiIdentifier) {
  return {
    isLoading: selectors.apiCallStatusSelectors.getApiCallStatus(
      state,
      apiIdentifier
    ),
    data: selectors.filterSectionSelectorsPt.getResults(state, apiIdentifier),
  };
}

/**
 * Method to  Format the State and pass them as Props to the component
 * @param {*} state  application State
 */
function mapStateToProps(state) {
  return {
    memberList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_TEAM_MEMBER_LIST
    ),
    selectedUserByImpersonator: state.homePageData.selectedUserByImpersonator,
    impersonatedUserDetails: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_IMPERSONATED_USER_DETAILS
    ),
    userDetails: selectors.ktHomeSelectors.getResults(
      state,
      API_CALL_IDENTIFIERS.FETCH_USER_DETAILS
    ),
    impersonatorUserList: getPropsForSeedData(
      state,
      API_CALL_IDENTIFIERS.FETCH_IMPERSONATOR_USER_LIST
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      updateDateFilter: bindActionCreators(
        actions.protoTypeActions.updateSelectedDate,
        dispatch
      ),
      updateSelectedUserByImpersonator: bindActionCreators(
        actions.homepageActions.updateSelectedUserByImpersonator,
        dispatch
      ),
      saveLoggedInUserId: bindActionCreators(
        actions.homepageActions.saveLoggedInUserId,
        dispatch
      ),
      fetchImpersonatedUserDetails: bindActionCreators(
        actions.homepageActions.updateImpersonatedUserDetails,
        dispatch
      ),
      saveAppliedFilters: bindActionCreators(
        actions.protoTypeActions.saveAppliedFilters,
        dispatch
      ),
      clearFilters: bindActionCreators(
        actions.protoTypeActions.clearFiltersData,
        dispatch
      ),
      updateImpersonatorUserList: bindActionCreators(
        actions.protoTypeActions.updateImpersonatedUserList,
        dispatch
      ),
      resetData: bindActionCreators(
        actions.homepageActions.updateIsResetData,
        dispatch
      ),
      clearDefaultFilters: bindActionCreators(
        actions.protoTypeActions.clearDefaultFilters,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KtImpersonator);
