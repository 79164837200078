import CONFIG from 'config/configProps';
import moment from 'moment';

export const getWeekPostedDates = (startDate, endDate) => {
  let weeks = getSaturdays(startDate, endDate);
  let weeksData = [];
  Object.keys(weeks).forEach(key => {
    let value = {
      id: moment(key).format(CONFIG.DATE_TIME_FORMAT_DISPLAY.API_FORMAT_ALL),
      label: key,
      children: formatData(weeks[key]),
      code: key,
      type: 'allWeekPostedDates',
    };
    weeksData.push(value);
  });
  return weeksData;
};

export const formatData = data => {
  let formattedData = [];
  data.forEach(item => {
    let value = {
      id: moment(item).format(CONFIG.DATE_TIME_FORMAT_DISPLAY.API_FORMAT),
      label: moment(item).format(
        CONFIG.DATE_TIME_FORMAT_DISPLAY.WEEK_POSTED_CHILD
      ),
      code: item,
      type: CONFIG.PROTOTYPE_FILTERS.WEEK_POSTED_DATE.field,
    };
    formattedData.push(value);
  });
  return formattedData;
};

export const getSaturdays = (startDate, endDate) => {
  let start = new Date(startDate);
  let end = new Date(endDate);
  let result = {};
  for (let day = start; day <= end; day.setDate(day.getDate() + 1)) {
    if (day.getDay() === 6) {
      let monthYear = day.toLocaleString('default', {
        month: 'short',
        year: 'numeric',
      });
      let date = moment(day).format(
        CONFIG.DATE_TIME_FORMAT_DISPLAY.WEEK_POSTED_CHILD
      ); // get date in yyyy-mm-dd format
      if (!result[monthYear]) {
        result[monthYear] = [];
      }
      result[monthYear].push(date);
    }
  }
  return result;
};
