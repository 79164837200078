import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CONFIG from 'config/configProps';
import { Grid } from 'semantic-ui-react';
import KTDownloads from 'components/KTDownloads';
import ToolTipShowEdit from 'components/common/ToolTipShowEdit';
import { globalAdobeAnalytics } from 'config/analytics';
import KTTimeSheetTable from './KTTimeSheetTable';
import actions from 'redux/actions';

const KTTimeSheet = () => {
  const adobeData = useSelector(state => state.adobeData.adobeAnalyticsData);
  const dispatch = useDispatch();

  const [showTooltip, setShowToolTip] = useState(false);

  useEffect(() => {
    if (!adobeData?.timesheet) {
      const isScriptLoaded = globalAdobeAnalytics(`KTDashboard-Timesheets`);
      isScriptLoaded && updateAdobeData();
    }
  }, [adobeData]);

  const updateAdobeData = () => {
    if (!adobeData?.timesheet)
      dispatch(
        actions.adobeAnalyticsActions.updateAdobeDetails({ timesheet: true })
      );
  };

  const closeToolTipBox = () => {
    setShowToolTip(false);
  };

  return (
    <Grid className="Kt_main_body">
      <Grid.Row className="Kt_main_body_heading">
        <Grid.Column
          className="Kt_main_body_heading_right"
          width={2}
          floated="right"
        >
          <KTDownloads
            showDownloadModal={false}
            reportName={CONFIG.TIMESHEET_DOWNLOAD_REPORT_NAME}
            url={CONFIG.PROTOTYPE_API_URLS.TIMESHEET_DOWNLOAD_URL}
            pageUrl={`KTDashboard-Timesheets`}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Column className="Kt_main_body_tabs" width={16}>
        <KTTimeSheetTable />
      </Grid.Column>
      {showTooltip && (
        <ToolTipShowEdit
          showPopup={showTooltip}
          handlePopupClose={closeToolTipBox}
          popupSelectedText={'Timesheets by Team Member'}
          popupBody={CONFIG.TIMESHEET_TOOLTIP_TEXT}
          showEditIcon={false}
          hideEditInfo={true}
          popupBodyClassName="infoText"
        />
      )}
    </Grid>
  );
};

export default KTTimeSheet;
